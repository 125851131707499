// 日期格式化
export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

export function getWeekDay(date) {
	let  daies = ["日", "一", "二", "三", "四", "五", "六"];
	return  "星期" + daies[date.getDay()];
}

export function getWeekDayNumber(weekDay) {
	let  daies = {
		"星期一": 1, 
		"星期二": 2, 
		"星期三": 3, 
		"星期四": 4,
		"星期五": 5, 
		"星期六": 6,
		"星期日": 7 
	};
	return daies[weekDay];
}

export function buildOptions(values, defaultKey, defaultValue, map) {
    const options = [];
    options.push({
        value: defaultValue,
        text: defaultKey
    })
    values.forEach(v => options.push(map(v)));
    return options;
}

export function getOption(values, value) {
    // if (!value) {
    //     return "";
    // }
    let results = values.filter(c => c.value == value);
    if (results && results.length > 0) {
        return results[0].text;
    } else {
        return "";
    }
}

export function getOptionValue(values, value) {
    let results = values.filter(c =>  c.value == value || c.text == value);
    if (results && results.length > 0) {
        return results[0].value;
    } else {
        return 0;
    }
}

export const resetImageUrl = (url, width , height) =>  {
	if( !url ) {
		return '';
	}
	if( !width && !height) {
		return url;
	}
	return `${url}?w=${width}&h=${height}`;
}

export function isPhoneNumber(value) {
    return /^((1[0-9]{2})+\d{8})$/.test(value);
}